import hearingyounow_logo from "../../../assets/images/hearingyounow_logo.png";

const Logo = ({ src = hearingyounow_logo }) => {
  return (
    <>
      <img src={src} className="logo" alt="HearingYouNow_logo" />
    </>
  );
};

export default Logo;
