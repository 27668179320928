import React, { useState, useEffect } from "react";

import speaker from "../../assets/images/speaker.png";

const VolumeControl = ({
  min = 0,
  max = 1,
  value = 0.2,
  onVolumeChanged = function (value) {},
}) => {
  const [volume, setVolume] = useState(value);
  const [levelBar, setLevelBar] = useState(20);

  const updateLevelBar = () => {
    if (value === 0.8) {
      setLevelBar(99);
    } else {
      setLevelBar(value * 100);
    }
    return value;
  };

  const changeVolume = () => {
    var newVolume = updateLevelBar(value);
    setVolume(newVolume);
    onVolumeChanged(newVolume);
  };

  useEffect(() => {
    changeVolume();
  }, []);

  useEffect(() => {
    if (volume === 0.8) {
      setLevelBar(99);
    } else {
      setLevelBar(volume * 100);
    }
  }, [volume]);

  useEffect(() => {
    changeVolume(value);
  }, [value]);

  return (
    <div className="volume__wrapper">
      <div className="volume__levelbar" style={{ width: levelBar + "%" }}></div>
      <div className="volume__container">
        <div className="volume__bar"></div>
        <div
          className="volume__icon"
          style={{ background: `url(${speaker}) no-repeat` }}
        ></div>
      </div>
    </div>
  );
};

export default VolumeControl;
