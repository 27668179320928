import React, { useState, useEffect } from "react";
import Joi from "joi-browser";

import { InputText, NextButton, ErrorMessage } from "../atomic";
import { Row, Col, FormGroup, Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import HearingTestService from "../../services/hearingTestService";

import ReferralFields from "../referralFields/ReferralFields";

export default function ReferralForm({
  disabled = false,
  sessionId,
  readOnly = false,
  onLoading = false,
}) {
  const { t } = useTranslation();
  const [referrals, setReferrals] = useState([]);
  const [currentReferral, setCurrentReferral] = useState({});

  const [errors, setErrors] = useState({});

  const [showReferralThankYou, setShowReferralThankYou] = useState(false);
  const [showAddReferralButton, setShowAddReferralButton] = useState(false);
  const [enableAddReferralButton, setEnableAddReferralButton] = useState(true);
  const [disabledFields, setDisabledFields] = useState(disabled || false);
  const [enableReferButton, setEnableReferButton] = useState(false);
  const [loading, setLoading] = useState(onLoading);

  const schema = {
    firstname: Joi.string()
      .required()
      .label("FirstName")
      .max(50)
      .regex(/^\s*\w+(?:[^\w,]+\w+)*[^,\w]*$/)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "FirstName is required" };
        });
      }),
    lastname: Joi.string()
      .required()
      .label("LastName")
      .max(100)
      .regex(/^\s*\w+(?:[^\w,]+\w+)*[^,\w]*$/)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "LastName is required" };
        });
      }),
    email: Joi.string()
      .email()
      .required()
      .label("Email")
      .error((errors) => {
        return errors.map((error) => {
          if (error.type === "string.email") {
            return { message: "Email address must be valid" };
          }
          return { message: "Email is required" };
        });
      }),
  };

  // useEffect(() => {
  //   const errorMessage = validateObject();

  //   if (errorMessage === null) {
  //     //setEnableAddReferralButton(true);
  //   } else {
  //     //setEnableAddReferralButton(false);
  //   }
  // }, [currentReferral]);

  const validateProperty = (name, value) => {
    const obj = { [name]: value };
    const newSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, newSchema);
    return error ? error.details[0].message : null;
  };

  const validateObject = () => {
    const { error } = Joi.validate(currentReferral, schema);
    return error ? error.details[0].message : null;
  };

  const validate = () => {
    const options = {
      abortEarly: false,
    };

    let JoiSchema = Joi.object().keys(schema);
    const { error } = Joi.validate(currentReferral, JoiSchema, options);

    if (!error) return null;

    const valErrors = {};
    for (let item of error.details) {
      if (!valErrors[item.path[0]]) valErrors[item.path[0]] = item.message;
    }

    return valErrors;
  };

  const validateEmailDuplicate = (email) => {
    var hasEmail = referrals.filter((item) => {
      if (item.email === email) {
        return item;
      }
      return null;
    });

    if (hasEmail && hasEmail.length > 0) {
      setErrors({ ...errors, email: "Email already exists" });
      return false;
    }

    return true;
  };

  const handleInputChanged = (event) => {
    setCurrentReferral({
      ...currentReferral,
      [event.target.name]: event.target.value,
    });

    const errorMessage = validateProperty(
      event.target.name,
      event.target.value
    );

    if (errorMessage) {
      setErrors({ ...errors, [event.target.name]: errorMessage });
      setEnableReferButton(false);
      return;
    } else {
      setEnableReferButton(true);
      delete errors[event.target.name];
      setErrors({ ...errors });
    }
  };

  const validateReferrals = () => {
    const options = {
      abortEarly: false,
    };

    let schemaReferrals = Joi.array().min(1).items(schema);
    const { error } = Joi.validate(referrals, schemaReferrals, options);

    if (!error) return null;

    const valErrors = {};
    for (let item of error.details) {
      if (!valErrors[item.path[0]]) valErrors[item.path[0]] = item.message;
    }

    return valErrors;
  };

  const handleAddReferral = (event) => {
    event.preventDefault();

    const errorMessage = validateObject();
    if (errorMessage != null) {
      setErrors({ ...errors, [event.target.name]: errorMessage });
      return;
    } else {
      setEnableAddReferralButton(true);
    }

    const emailDuplicateError = validateEmailDuplicate(currentReferral.email);
    if (!emailDuplicateError) {
      return;
    }

    let referral = currentReferral;
    setReferrals([...referrals, referral]);

    setCurrentReferral({ firstname: "", lastname: "", email: "" });
  };

  const handleSubmitReferral = (event) => {
    event.preventDefault();

    try {
      const errorMessage = validate();

      if (errorMessage) {
        setEnableReferButton(false);
        setErrors(errorMessage);
        return;
      } else {
        setEnableReferButton(true);
        setErrors({});
      }

      let tempReferrals = [...referrals];
      const currentReferralValid = validateObject();
      if (!currentReferralValid) {
        let referral = currentReferral;
        tempReferrals.push(referral);
        setReferrals([...referrals, referral]);
        setCurrentReferral({ firstname: "", lastname: "", email: "" });
      }

      setEnableReferButton(false);
      setShowAddReferralButton(false);
      setEnableAddReferralButton(false);
      setLoading(true);

      HearingTestService.referFriends(sessionId, tempReferrals)
        .then((response) => {
          setLoading(false);
          setShowReferralThankYou(true);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
          setShowReferralThankYou(true);
        });
    } catch (error) {
      setLoading(false);
      setEnableReferButton(true);
      setEnableAddReferralButton(true);
    }
  };

  return (
    <>
      <Row className={`mb-3 ${!showReferralThankYou ? "d-block" : "d-none"}`}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="text-container text-center"
        >
          {ReactHtmlParser(
            t(
              "Invite a friend to also take our confidential online hearing test"
            )
          )}
        </Col>
      </Row>

      <Row className={`mt-3`}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReferralFields referrals={referrals} disabled={true} />
        </Col>
      </Row>

      <Row className={`${!showReferralThankYou ? "d-flex" : "d-none"}`}>
        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          className="input-container font-weight-bold"
        >
          <FormGroup>
            <InputText
              name="firstname"
              value={currentReferral.firstname}
              required
              placeholder={ReactHtmlParser(t("FirstName"))}
              onChange={handleInputChanged}
              disabled={disabledFields}
            />
            <ErrorMessage message={errors.firstname} />
          </FormGroup>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          className="input-container font-weight-bold pl-1 pr-1 input-lastname"
        >
          <FormGroup>
            <InputText
              name="lastname"
              value={currentReferral.lastname}
              required
              placeholder={ReactHtmlParser(t("LastName"))}
              onChange={handleInputChanged}
              disabled={disabledFields}
            />
            <ErrorMessage message={errors.lastname} />
          </FormGroup>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="input-email-container input-container font-weight-bold"
        >
          <FormGroup>
            <InputText
              name="email"
              type="email"
              required
              value={currentReferral.email}
              placeholder={ReactHtmlParser(t("Email"))}
              onChange={handleInputChanged}
              disabled={disabledFields}
              readOnly={readOnly}
            />
            <ErrorMessage message={errors.email} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="d-flex justify-content-flex-end btn-addReferral-container p-0"
        >
          <Button
            onClick={handleAddReferral}
            className={`btn-add-more-friends text-right ${
              enableAddReferralButton ? "d-block" : "d-none"
            }`}
            disabled={!enableAddReferralButton}
          >
            {ReactHtmlParser(t("Add more friends"))}
          </Button>
        </Col>
      </Row>

      <Row
        className={`justify-content-flex-end btn-addReferral-container p-0 ${
          !showReferralThankYou ? "d-block" : "d-none"
        }`}
      >
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`text-center`}>
          <NextButton
            type="submit"
            onClick={handleSubmitReferral}
            text={ReactHtmlParser(t("Send"))}
            disable={!enableReferButton}
            loading={loading}
            className="btn-thankYou-next"
          />
        </Col>
      </Row>

      <Row
        className={`mt-1 msg-thankyou-referral ${
          showReferralThankYou ? "d-block" : "d-none"
        }`}
      >
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
          {ReactHtmlParser(t("THANKYOU_ReferralFriends"))}
        </Col>
      </Row>
    </>
  );
}
