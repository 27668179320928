import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";

const VolumeUpIcon = ({ show = true }) => {
  return (
    <div className={`${show ? `d-display` : `d-none`}`}>
      <FontAwesomeIcon icon={faVolumeUp} className="speakerIcon" />
    </div>
  );
};

export default VolumeUpIcon;
