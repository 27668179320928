import { Modal, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ReactHtmlParser from "react-html-parser";

import { ActionButton } from "../atomic";

import WarningImage from "../../assets/images/alert-circle.png";

const MaxVolumeModal = ({ show, onDidntHearTone, onTryAgain }) => {
  const { t } = useTranslation();

  const handleDidntHearTone = (event) => {
    onDidntHearTone(event);
  };

  const handleTryAgain = (event) => {
    onTryAgain(event);
  };

  return (
    <>
      <Modal show={show} className="maxVolumeModal">
        <Modal.Body>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="text-center"
            >
              <img src={WarningImage} alt="Warning" />
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="text-center"
            >
              <span>{t("You have reached the maximum volume")}</span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="text-center"
            >
              <ActionButton
                onClick={handleDidntHearTone}
                text={ReactHtmlParser(t("You didn't hear a tone"))}
                className="btn-maxVolume__next"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="text-center"
            >
              <button
                onClick={handleTryAgain}
                className="btn btn-link"
                role="link"
              >
                {ReactHtmlParser(t("Try again"))}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MaxVolumeModal;
