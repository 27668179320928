import React from "react";

import { HashRouter as Router, Route, Switch } from "react-router-dom";

import "./scss/style.scss";

import "./App.css";

import Result from "./views/result/Result";

import NotFound from "./views/pages/notFound/NotFound";
import { createBrowserHistory } from "history";
import DefaultLayout from "./containers/HearingTest/DefaultLayout";

import GA4React from "ga-4-react";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";
import ReactPixel from "react-facebook-pixel";

//const advancedMatching = { em: "some@email.com" };
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};
//ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL, {}, options);
const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_G4_ID);
hotjar.initialize(
  process.env.REACT_APP_HOTJAR_ID,
  process.env.REACT_APP_HOTJAR_SV
);

const history = createBrowserHistory();

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
};

TagManager.initialize(tagManagerArgs);

history.listen(async (location, action) => {
  (async (_) => {
    if (ga4react !== undefined && window.gtag !== undefined) {
      const newPath = location.hash.replace("#", "");
      ga4react.pageview(newPath);
      ga4react.gtag("event", "pageview", newPath);
    }

    //ReactPixel.pageView();
  })();
});

function App() {
  const fallbackLoading = () => (
    <div className="animated fadeIn pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  return (
    <>
      <Router history={history}>
        <React.Suspense fallback={fallbackLoading()}>
          <Switch>
            <Route
              path="/result/:id"
              name="Result"
              component={Result}
              exact={true}
            />

            <DefaultLayout />

            <Route path="/404" component={NotFound} />
          </Switch>
        </React.Suspense>
      </Router>
    </>
  );
}

export default App;
