import { Row, Col } from "react-bootstrap";

const StepTitle = ({ text, className }) => {
  return (
    <>
      <Row className={`steptitle__content ${className}`}>
        <Col>{text}</Col>
      </Row>
    </>
  );
};

export default StepTitle;
