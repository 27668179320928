import React, { useState, useEffect } from "react";
import Joi from "joi-browser";

import { Row, Col, FormGroup } from "react-bootstrap";

import ReactHtmlParser from "react-html-parser";
import Select from "react-select";

import { useTranslation } from "react-i18next";

import { NextButton } from "../../components/atomic";
import { InputText, Label, ErrorMessage } from "../../components/atomic";

import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

import HearingTestService from "../../services/hearingTestService";

import TermsConditions from "../../assets/docs/Terms_and_Conditions_and_Disclaimer.pdf";
import useGetUserLocationDetails from "../../hooks/useGetUserLocationDetails";

import countryList from "../../assets/data/countries.json";
import auStatesList from "../../assets/data/auStates.json";
import usStatesList from "../../assets/data/usStates.json";
import { selectStyle } from "../../assets/styles/controls";
//import ReactPixel from "react-facebook-pixel";

const ContactForm = ({ onComplete }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    age: "",
    gender: "",
    country: "",
    state: "",
    terms: false,
  });

  const [errors, setErrors] = useState({});
  const [enableButtonNext, setEnableButtonNext] = useState(false);
  const { country: userCountry } = useGetUserLocationDetails();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [showCountryField, setShowCountryField] = useState(false);
  const [showStates, setShowStates] = useState(false);

  const [genders] = useState([
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ]);

  const schemaObj = {
    firstname: Joi.string()
      .required()
      .label("First Name")
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    lastname: Joi.string()
      .required()
      .label("Last Name")
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    email: Joi.string()
      .email()
      .required()
      .label("Email")
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    phoneNumber: Joi.string()
      .required()
      .label("Phone number")
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    age: Joi.number()
      .integer()
      .required()
      .min(0)
      .max(120)
      .error((errors) => {
        return errors.map((error) => {
          if (error.type === "number.max") {
            return { message: "Age can not be negative or more than 120" };
          } else if (error.type === "number.min") {
            return { message: "Age can not be negative or more than 120" };
          }
          return { message: "Required" };
        });
      }),
    gender: Joi.any()
      .valid(["Male", "Female"])
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    country: Joi.string()
      .required()
      .label("Country")
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    state: Joi.when("country", { is: "US", then: Joi.string().required() })
      .when("country", { is: "AU", then: Joi.string().required() })
      .label("State")
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    terms: Joi.any()
      .valid([true])
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
  };

  const loadCountries = () => {
    const loadedCountries = countryList.map((item) => {
      return { label: item.name, value: item.alpha2.toUpperCase() };
    });

    setCountries(loadedCountries);
  };

  const loadStates = (selectedCountry) => {
    if (selectedCountry === "US") {
      setStates(usStatesList);
      setShowCountryField(false);
      setShowStates(true);
    } else if (selectedCountry === "AU") {
      setStates(auStatesList);
      setShowCountryField(false);
      setShowStates(true);
    } else {
      setData((prevValues) => ({
        ...prevValues,
        ["state"]: "",
      }));
      setShowStates(false);
    }
  };

  const validate = () => {
    const options = {
      abortEarly: false,
    };

    let JoiSchema = Joi.object().keys(schemaObj);
    const { error } = Joi.validate(data, JoiSchema, options);

    if (!error) return null;

    const valErrors = {};
    for (let item of error.details) {
      if (!valErrors[item.path[0]]) valErrors[item.path[0]] = item.message;
    }

    return valErrors;
  };

  const validateProperty = (name, value) => {
    const obj = { [name]: value };
    const newSchema = { [name]: schemaObj[name] };
    const { error } = Joi.validate(obj, newSchema);

    return error ? error.details[0].message : null;
  };

  const handleChanged = (name, value) => {
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    loadCountries();
  }, []);

  const isFormValid = () => {
    const errorResult = validate();
    if (errorResult) {
      setErrors(errorResult);
    } else {
      setErrors({});
      setEnableButtonNext(true);
    }
  };

  const enableNextButtonIfFormIsValid = () => {
    const errorResult = validate();
    if (errorResult) {
      setEnableButtonNext(false);
    } else {
      setErrors({});
      setEnableButtonNext(true);
    }
  };

  const renderCountryControl = () => {
    return (
      <Select
        id="country"
        name="country"
        required
        selectValue={data.country}
        options={countries}
        styles={selectStyle}
        onChange={handleSelectChange}
        isSearchable={true}
      />
    );
  };

  const renderStatesControl = () => {
    return (
      <Select
        id="state"
        name="state"
        required
        selectValue={data.state}
        options={states}
        styles={selectStyle}
        onChange={handleSelectChange}
        isSearchable={true}
      />
    );
  };

  const handleSelectChange = (selectedOption, element) => {
    const errorMessage = validateProperty(element.name, selectedOption.value);
    if (errorMessage != null) {
      errors[element.name] = errorMessage;
    } else {
      delete errors[element.name];
      setErrors(errors);
    }

    if (element.name == "country") {
      loadStates(selectedOption.value);
    }

    enableNextButtonIfFormIsValid();

    handleChanged(element.name, selectedOption.value);
  };

  const handleCheckBoxChange = (event) => {
    const errorMessage = validateProperty(
      event.target.name,
      !event.target.value
    );

    if (errorMessage != null) {
      errors[event.target.name] = errorMessage;
    } else {
      delete errors[event.target.name];
      setErrors(errors);
    }

    handleChanged(event.target.name, event.target.checked);
  };

  const handleInputChanged = (event) => {
    const errorMessage = validateProperty(
      event.target.name,
      event.target.value
    );

    if (errorMessage != null) {
      errors[event.target.name] = errorMessage;
    } else {
      delete errors[event.target.name];
      setErrors(errors);
    }

    //enableNextButtonIfFormIsValid();

    handleChanged(event.target.name, event.target.value);
  };

  const handleSubmit = (event) => {
    try {
      const errorResult = validate();
      if (errorResult) {
        setErrors(errorResult);
        return;
      } else {
        setErrors({});
      }

      setEnableButtonNext(false);
      setLoading(true);
      HearingTestService.CreateUser(data)
        .then((response) => {
          //ReactPixel.track("Start Test");
          fireYahooPixelEvent();

          onComplete(response.data.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    } catch (error) {
      setEnableButtonNext(true);
    }
  };

  const fireYahooPixelEvent = () => {
    window.dotq = window.dotq || [];
    window.dotq.push({
      projectId: "10000",
      properties: {
        pixelId: "10160654",
        qstrings: {
          et: "custom",
          ec: "startthetest",
        },
      },
    });
  };

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
          <FormGroup>
            <div className="w-100 text-left ml-3">
              <Label
                text={ReactHtmlParser(t("First Name"))}
                className="text-left"
              />
            </div>
            <InputText
              id="firstname"
              name="firstname"
              value={data.firstname}
              required
              disable={!enableButtonNext}
              placeholder={ReactHtmlParser(t("First Name"))}
              onChange={handleInputChanged}
            />
            <ErrorMessage message={errors.firstname} />
          </FormGroup>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
          <FormGroup>
            <div className="w-100 text-left ml-3">
              <Label
                text={ReactHtmlParser(t("Last Name"))}
                className="text-left"
              />
            </div>
            <InputText
              id="lastname"
              name="lastname"
              value={data.lastname}
              required
              placeholder={ReactHtmlParser(t("Last Name"))}
              onChange={handleInputChanged}
            />
            <ErrorMessage message={errors.lastname} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
          <FormGroup>
            <div className="w-100 text-left ml-3">
              <Label
                text={ReactHtmlParser(t("E-mail address"))}
                className="text-left"
              />
            </div>
            <InputText
              id="email"
              name="email"
              type="email"
              required
              value={data.email}
              placeholder={ReactHtmlParser(t("E-mail address"))}
              onChange={handleInputChanged}
            />
            <ErrorMessage message={errors.email} />
          </FormGroup>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
          <FormGroup>
            <div className="w-100 text-left ml-3">
              <Label
                text={ReactHtmlParser(t("Phone number"))}
                className="text-left"
              />
            </div>
            <InputText
              id="phoneNumber"
              name="phoneNumber"
              required
              value={data.phoneNumber}
              placeholder={ReactHtmlParser(t("Phone number"))}
              onChange={handleInputChanged}
            />
            <ErrorMessage message={errors.phoneNumber} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
          <FormGroup>
            <div className="w-100 text-left ml-3">
              <Label text={ReactHtmlParser(t("Age"))} className="text-left" />
            </div>
            <InputText
              id="age"
              name="age"
              type="numeric"
              value={data.age}
              required
              placeholder={ReactHtmlParser(t("Age"))}
              onChange={handleInputChanged}
            />
            <ErrorMessage message={errors.age} />
          </FormGroup>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
          <FormGroup>
            <div className="w-100 text-left ml-3">
              <Label
                text={ReactHtmlParser(t("Gender"))}
                className="text-left"
              />
            </div>
            <Select
              id="gender"
              name="gender"
              required
              selectValue={data.gender}
              options={genders}
              styles={selectStyle}
              onChange={handleSelectChange}
              isSearchable={false}
            />
            <ErrorMessage message={errors.gender} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
          <FormGroup>
            <div className="w-100 text-left ml-3">
              <Label
                text={ReactHtmlParser(t("Country"))}
                className="text-left"
              />
            </div>
            {renderCountryControl()}

            <ErrorMessage message={errors.country} />
          </FormGroup>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
          <FormGroup className={showStates ? "d-block" : "d-none"}>
            <div className="w-100 text-left ml-3">
              <Label text={ReactHtmlParser(t("State"))} className="text-left" />
            </div>
            {renderStatesControl()}

            <ErrorMessage message={errors.state} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
          <FormGroup className="w-100 text-left">
            <label>
              <Checkbox
                id="terms"
                name="terms"
                value={data.terms}
                onChange={handleCheckBoxChange}
              />
              <span>
                &nbsp; I have read and agreed to the{" "}
                <a href={TermsConditions} target="_blank" rel="noreferrer">
                  terms
                </a>{" "}
                & conditions and{" "}
                <a href={TermsConditions} target="_blank" rel="noreferrer">
                  privacy policy
                </a>
                .
              </span>
            </label>
            <ErrorMessage message={errors.terms} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="mb-5 text-center"
        >
          {/* <BackButton
              onClick={handlePreviousStep}
              disable={!enableButtonBack}
            /> */}
          <NextButton
            type="submit"
            onClick={handleSubmit}
            text={ReactHtmlParser(t("Start the test"))}
            className="btn-getResults"
            //disable={!enableButtonNext}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default ContactForm;
