import { Row, Col } from "react-bootstrap";

import "./style.css";

const TextDescription = ({ text }) => {
  return (
    <>
      <Row className="textdescription__copy">
        <Col>{text}</Col>
      </Row>
    </>
  );
};

export default TextDescription;
