export const selectStyle = {
  control: (styles) => ({
    ...styles,
    borderRadius: "28px",
    backgroundColor: "white",
    height: "60px",
    ":hover": {
      ...styles[":hover"],
      border: "1px solid #3756e9;",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      textAlign: "left",
      backgroundColor: "#FFF",
      borderRadius: "0 28px",
      color: "#000",
      ":active": {
        ...styles[":active"],
        backgroundColor: "#FFF",
      },
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles }),
};
