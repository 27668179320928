import React from "react";

const InputText = React.forwardRef(
  (
    {
      id,
      name,
      type = "text",
      value,
      required = false,
      placeholder,
      className = "",
      disabled = false,
      onChange,
      onKeyDown,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <input
          id={id}
          name={name}
          ref={ref}
          type={type}
          value={value}
          className={`inputText ${className}`}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          disabled={disabled ? "disabled" : ""}
          required={required ? "required" : ""}
        />
      </>
    );
  }
);

export default InputText;
