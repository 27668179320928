import { Button, Spinner } from "react-bootstrap";

const NextButton = ({
  id,
  name,
  text,
  type = "button",
  onClick,
  className = "",
  disable = false,
  loading = false,
}) => {
  const renderButtonContent = () => {
    if (loading) {
      return (
        <>
          <Spinner
            as="span"
            animation="border"
            size="md"
            role="status"
            aria-hidden="true"
            className="mr-2"
          />
          Loading...
        </>
      );
    }

    return text;
  };

  return (
    <>
      <Button
        id={id}
        name={name}
        type={type}
        onClick={onClick}
        className={`btnNext ${className}`}
        disabled={loading || disable ? "disabled" : ""}
      >
        {renderButtonContent()}
      </Button>
    </>
  );
};

export default NextButton;
