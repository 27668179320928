import { Row, Col } from "react-bootstrap";

import "./style.css";

const Box = ({ img, text }) => {
  return (
    <>
      <div className="d-block box">
        <Row className="mb-3 box__row-image">
          <Col>
            <img src={img} alt="" className="box__image" />
          </Col>
        </Row>
        <Row>
          <Col className="box__row-copy">
            <span className="box__copy">{text}</span>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Box;
