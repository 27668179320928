import i18n from "i18next";
import Backend from "i18next-xhr-backend";
//import Cache from "i18next-localstorage-cache";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(Backend)
    //.use(Cache)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: "en",
        backend: {
            loadPath: "/assets/i18n/{{ns}}/{{lng}}.json"
        },
        fallbackLng: "en",
        debug: false,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        transSupportBasicHtmlNodes: true,
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",

            // format: function (value, format, lng) {
            //     if (format === 'uppercase')
            //         return value.toUpperCase();

            //     if (format === 'decimal') {
            //         if (value !== undefined) {
            //             return new Intl.NumberFormat(lng, { style: 'decimal' }).format(value);
            //         }
            //     } else if (format === 'decimalES') {
            //         if (value !== undefined) {
            //             return new Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(value);
            //         }
            //     } else if (format === 'decimalWithSpace') {
            //         if (value !== undefined) {
            //             return new Intl.NumberFormat(lng, { style: 'decimal' }).format(value);
            //         }
            //     }

            //     if (value instanceof Date)
            //         return Moment(value).format(format);

            //     return value;
            // }
        },
        react: {
            wait: true
        }
    });

export default i18n;
