import React from "react";
import LoadingOverlay from "react-loading-overlay";
import CircleLoader from "react-spinners/GridLoader";

export default function SpinningLoader({ active, children }) {
  return (  
    <LoadingOverlay active={active} spinner={<CircleLoader />}>
      {children}
    </LoadingOverlay>
  );
}
