import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

import ReactHtmlParser from "react-html-parser";

import { NextButton } from "../../components/atomic";

import Box from "../../components/box/Box";

import headphones from "../../assets/images/headphones.png";
import message_balloon_strike from "../../assets/images/message_balloon_strike.png";
import TextDescription from "../../components/textdescription/TextDescription";
import StepTitle from "../../components/steptitle/StepTitle";
import AdBlockPopup from "../../components/adBlockPopup/AdBlockPopup";

const Instructions = ({ onLoading }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const handleNextStep = (event) => {
    event.preventDefault();

    if (onLoading) {
      onLoading();
    }

    history.push(`/VolumeSetting/${id}`);
  };

  return (
    <>
      <div className="instructions__container mt-5">
        <Row className="mb-1">
          <Col md={12}>
            <StepTitle text={ReactHtmlParser(t("INSTRUCTIONS_TITLE"))} />
          </Col>
        </Row>
        <Row className="mb-sm-3 mb-5">
          <Col md={12}>
            <TextDescription
              text={ReactHtmlParser(t("INSTRUCTIONS_DESCRIPTION"))}
            />
          </Col>
        </Row>
        <Row className="mb-sm-3 mb-5">
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
              img={headphones}
              text={ReactHtmlParser(t("INSTRUCTIONS_BOX_1"))}
            />
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
              img={message_balloon_strike}
              text={ReactHtmlParser(t("INSTRUCTIONS_BOX_3"))}
            />
          </Col>
        </Row>
        <Row className="mb-sm-3 mt-5">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
            <NextButton
              onClick={handleNextStep}
              text={ReactHtmlParser(t("Continue"))}
            />
          </Col>
        </Row>
        <AdBlockPopup />
      </div>
    </>
  );
};

export default Instructions;
