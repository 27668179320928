import React, { useState, useEffect } from "react";

import StepWizard from "react-step-wizard";
import { useHistory, useParams } from "react-router-dom";

import { ProgressBarNav } from "../../components/atomic";

import Words from "../words/Words";
import wordsList from "../../words.json";
import HearingTestService from "../../services/hearingTestService";

const PhoneticallyWordsStepWizard = () => {
  const history = useHistory();
  const { id: sessionId, hz } = useParams();

  const [initialStep] = useState(1);
  const [currentStep] = useState(1);

  const [volume, setVolume] = useState(0.55);
  const [currentHz, setCurrentHz] = useState();

  const handleStepChange = (step) => {};

  useEffect(() => {
    let data = {
      session_id: sessionId,
    };

    if (!hz) {
      HearingTestService.CalculateAverageVolume(data)
        .then((response) => {
          setVolume(response.data.data.volume);
          setCurrentHz(response.data.data.volume);

          history.push(
            `/test/${sessionId}/words/${response.data.data.volume}/word/1/`
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }, []);

  return (
    <>
      <div className="phoneticallyWordsStepWizard__container">
        <StepWizard
          isHashEnabled={true}
          nav={<ProgressBarNav />}
          className="mt-5"
          initialStep={initialStep}
          currentStep={currentStep}
          goToStep={currentStep}
          previousStep={currentStep - 1}
          onStepChange={handleStepChange}
        >
          {wordsList &&
            wordsList.length > 0 &&
            wordsList.map((value, idx) => {
              if (value.hz == hz) {
                return (
                  <Words
                    key={idx}
                    hashKey={`/test/${sessionId}/words/${value.hz}/word/${value.id}/`}
                    id={value.id}
                    sessionId={sessionId}
                    word={value.word}
                    value={value}
                    ear={value.ear}
                    volume={volume}
                    src={value.src}
                  />
                );
              }
            })}
        </StepWizard>
      </div>
    </>
  );
};

export default PhoneticallyWordsStepWizard;
