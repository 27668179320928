import { useTranslation } from "react-i18next";
import { Navbar, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Logo from "../logo/Logo";

import hearingyounow_logo from "../../../assets/images/hearingyounow_logo.png";

import call from "../../../assets/images/call.png";

import useGetUserLocationDetails from "../../../hooks/useGetUserLocationDetails";

const Header = () => {
  const { t } = useTranslation();
  const { country: userCountry } = useGetUserLocationDetails();

  const renderAUPhone = () => {
    return (
      <a href="tel:1300903968" className="contactPhone__link ml-3">
        <span className="contactPhone__icon">
          <img src={call} alt="" loading="lazy" className="icon"></img>
        </span>
        <span className="contactPhone__text">1300 903 968</span>
      </a>
    );
  };

  const renderUSPhone = () => {
    return (
      <a href="tel:18002816122" className="contactPhone__link ml-3">
        <span className="contactPhone__icon">
          <img src={call} alt="" loading="lazy" className="icon"></img>
        </span>
        <span className="contactPhone__text">1800 281 6122</span>
      </a>
    );
  };

  const renderPhoneNumber = () => {    
    if (userCountry) {
      if (userCountry == "AU") {
        return renderAUPhone();
      } else if (userCountry == "US") {
        return renderUSPhone();
      } else {
        return renderUSPhone();
      }
    } else {
      return renderUSPhone();
    }
  };

  return (
    <>
      <header className="header">
        <Navbar className="custom-navbar">
          <Container fluid>
            <div className="navbar-toggle-blocks-left">
              <Navbar.Brand href="/" className="d-block">
                <Logo src={hearingyounow_logo} />
              </Navbar.Brand>
            </div>

            <div className="navbar-toggle-blocks-right">
              <div className="container header__container">
                <div className="needHelp__container">
                  <span className="needHelp__text">{t("Need Help?")}</span>
                </div>

                <div className="contactPhone__container">
                  {renderPhoneNumber()}
                </div>

                <div className="contactEmail__container">
                  <a
                    href="emailto:support@hearingyounow.com"
                    className="contactEmail__link"
                  >
                    <span className="contactEmail__icon">
                      <FontAwesomeIcon icon={faEnvelope} className="icon" />
                    </span>

                    <span className="contactEmail__text ml-3">
                      {"support@hearingyounow.com"}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
