import HTTPService from "./httpService";

const apiEndpoint = process.env.REACT_APP_IPGEOLOCATION_API_URL;

const getLocationDetailsByIPAddress = async (ipAddress) => {
    return await HTTPService.get(`${apiEndpoint}/${ipAddress}/json?token=${process.env.REACT_APP_IPGEOLOCATION_TOKEN}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            //'Referer': 'hearingyounow.com'
        }
    });
}

const getUserLocationDetails = async () => {
    return await HTTPService.get(`${apiEndpoint}/check/?token=${process.env.REACT_APP_IPGEOLOCATION_TOKEN}`);
}

export default {
    getLocationDetailsByIPAddress,
    getUserLocationDetails,
};
