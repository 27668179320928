import HTTPService from "./httpService";

const apiEndpoint = process.env.REACT_APP_HEARINGYOUNOW_API_URL;

const startSession = async (data) => {
    return await HTTPService.post(apiEndpoint + "/sessions/start", data);
}

const createUser = async (data) => {
    return await HTTPService.post(apiEndpoint + "/users", data);
}

const createHearingTest = async (data) => {
    return await HTTPService.post(apiEndpoint + "/HearingTests", data);
}

const createPhoneticallyWord = async (data) => {
    return await HTTPService.post(apiEndpoint + "/phoneticallywords", data);
}

const calculateAverageVolume = async (data) => {
    return await HTTPService.get(apiEndpoint + "/calcs/averagevolume/" + data.session_id);
}

const getResult = async (data) => {
    return await HTTPService.get(apiEndpoint + "/result/" + data.session_id);
}

const generateReport = async (sessionId) => {
    return await HTTPService.post(`${apiEndpoint}/reports/generate/${sessionId}`);
}

const referFriends = async (sessionId, data) => {
    return await HTTPService.post(`${apiEndpoint}/referrals/${sessionId}`, data);
}


export default {
    StartSession: startSession,
    CreateUser: createUser,
    CreateHearingTest: createHearingTest,
    CreatePhoneticallyWord: createPhoneticallyWord,
    CalculateAverageVolume: calculateAverageVolume,
    GetResult: getResult,
    GenerateReport: generateReport,
    referFriends,
};
