import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";

import StepTitle from "../../components/steptitle/StepTitle";
import HearingTestService from "../../services/hearingTestService";
import ReferralForm from "../../components/referralForm/ReferralForm";
import useGetUserLocationDetails from "../../hooks/useGetUserLocationDetails";

import AudiogramResult from "../../components/audiogramResult/AudiogramResult";

import "./style.scss";

const ThankYou = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [sessionId, setSessionId] = useState("");

  const { country: userCountry } = useGetUserLocationDetails();
  const [currentCountry, setCurrentCountry] = useState("");

  useEffect(() => {}, []);

  useEffect(() => {
    setSessionId(id);
    
    HearingTestService.GenerateReport(id)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, [id]);

  useEffect(() => {
    if (userCountry) {
      if (userCountry == "AU") {
        setCurrentCountry(process.env.REACT_APP_PHONE_AU);
      } else if (userCountry == "US") {
        setCurrentCountry(process.env.REACT_APP_PHONE_US);
      } else {
        setCurrentCountry(process.env.REACT_APP_PHONE_US);
      }
    } else {
      setCurrentCountry(process.env.REACT_APP_PHONE_US);
    }
  }, [userCountry]);

  return (
    <>
      <div className="p-4 p-xs-0 mt-5 box-thankyou">
        <Row className="mt-2">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="text-container text-large"
          >
            <StepTitle
              className="box-thankyou-title"
              text={ReactHtmlParser(t("THANKYOU_MESSAGE"))}
            />
            <StepTitle
              className="box-thankyou-title"
              text={ReactHtmlParser(
                t("THANKYOU_AUDIOGRAMRESULTS", { phone: currentCountry })
              )}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="text-container text-center font-bold text-medium p-0"
          >
            {ReactHtmlParser(
              t(
                "Your audiogram results are below for both your left ear and your right ear."
              )
            )}
          </Col>
        </Row>

        <Row className="mt-3">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="text-container text-center text-medium"
          >
            {ReactHtmlParser(t("These will also be <b>emailed to you.</b>"))}
          </Col>
        </Row>

        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="text-container text-center text-medium"
          >
            {ReactHtmlParser(
              t(
                "If you have not received your results please <b>check in your spam or junk folder.</b>"
              )
            )}
          </Col>
        </Row>

        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="text-container text-center text-medium"
          >
            {ReactHtmlParser(
              t("Call or email us if you still haven't received your results.")
            )}
          </Col>
        </Row>

        <Row className="mt-3">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="text-container text-center text-italic text-medium"
          >
            {ReactHtmlParser(
              t(
                "<b>One of our experts will call you shortly to discuss your results.</b>"
              )
            )}
          </Col>
        </Row>

        <Row className="mt-1">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="text-container text-center text-italic text-medium"
          >
            {ReactHtmlParser(
              t("<b>They will not try to sell you anything.</b>")
            )}
          </Col>
        </Row>

        <Row className="mt-3">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="text-container text-center font-weight-bold text-small"
          >
            {ReactHtmlParser(
              t(
                "* This is not intended as a substitute for a full audiologist test conducted in a clinical environment by a licensed clinician."
              )
            )}
          </Col>
        </Row>
      </div>

      <div className="mt-5 mb-5 result-container">
        <AudiogramResult sessionId={sessionId} />
      </div>

      <div className="mt-5 p-xs-2 referral-container">
        <Row className={`mt-3`}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <ReferralForm className="mt-3" sessionId={sessionId} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ThankYou;
