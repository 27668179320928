import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

import StepText from "../../components/stepText/StepText";
import ContactForm from "../../components/contactForm/ContactForm";
import AdBlockPopup from "../../components/adBlockPopup/AdBlockPopup";
import useGetUserLocationDetails from "../../hooks/useGetUserLocationDetails";
//import ReactPixel from "react-facebook-pixel";

import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [currentCountry, setCurrentCountry] = useState("");
  const { id } = useParams();
  const { country: userCountry } = useGetUserLocationDetails();

  useEffect(() => {
    if (userCountry) {
      if (userCountry == "AU") {
        setCurrentCountry(process.env.REACT_APP_PHONE_AU);
      } else if (userCountry == "US") {
        setCurrentCountry(process.env.REACT_APP_PHONE_US);
      } else {
        setCurrentCountry(process.env.REACT_APP_PHONE_US);
      }
    } else {
      setCurrentCountry(process.env.REACT_APP_PHONE_US);
    }
  }, [userCountry]);

  const handleSubmit = (data) => {
    try {
      history.push(`/instructions/${data.token}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="contact__container mt-3">
        {id ? (
          <>
            <Row className="mb-1">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <StepText
                  text={ReactHtmlParser(
                    t("CONTACT_TITLE", { phone: currentCountry })
                  )}
                  className="contactform__title"
                />
              </Col>
            </Row>

            <Row className="mb-1">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <StepText
                  text={ReactHtmlParser(t("CONTACT_SUBTITLE"))}
                  className="contactform__subtitle"
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="mb-1">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <StepText
                  text={ReactHtmlParser(
                    t("CONTACT_TITLE", { phone: currentCountry })
                  )}
                  className="contactform__title"
                />
              </Col>
            </Row>
            <Row className="mb-1">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <StepText
                  text={ReactHtmlParser(
                    t("CONTACT_SUBTITLE", {
                      link: "/assets/docs/Terms_and_Conditions_and_Disclaimer.pdf",
                    })
                  )}
                  className="contactform__subtitle"
                />
              </Col>
            </Row>
          </>
        )}
        <ContactForm onComplete={handleSubmit} />
      </div>

      <AdBlockPopup />
    </>
  );
};

export default Contact;
