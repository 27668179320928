import { useState, useEffect } from "react";
import IPGeoLocationService from "../services/ipGeoLocationService";
import { v4 } from "public-ip";

const useGetUserLocationDetails = () => {
  const [location, setLocation] = useState({});
  const [country, setCountry] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    async function getLocationDetailsByIPAddress() {
      const userIPAddressResponse = await v4();

      const userIPLocation =
        await IPGeoLocationService.getLocationDetailsByIPAddress(
          userIPAddressResponse
        );

      setCountry(userIPLocation.data.country);
      setLocation(userIPLocation);
    }

    getLocationDetailsByIPAddress();
  }, []);

  return { country, location };
};

export default useGetUserLocationDetails;