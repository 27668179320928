import React from "react";

import { InputText } from "../atomic";
import { Row, Col, FormGroup } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";

export default function ReferralFields({ disabled = false, referrals = [] }) {
  const { t } = useTranslation();

  const renderReferrals = () => {
    return referrals.map((item, index) => {
      return (
        <>
          <Row key={index}>
            <Col
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className="input-container font-weight-bold"
            >
              <FormGroup>
                <InputText
                  name="firstname"
                  value={item.firstname}
                  placeholder={ReactHtmlParser(t("FirstName"))}
                  disabled={disabled}
                />
              </FormGroup>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className="input-container font-weight-bold"
            >
              <FormGroup>
                <InputText
                  name="lastname"
                  value={item.lastname}
                  placeholder={ReactHtmlParser(t("LastName"))}
                  disabled={disabled}
                />
              </FormGroup>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="input-email-container input-container font-weight-bold"
            >
              <FormGroup>
                <InputText
                  name="email"
                  type="email"
                  value={item.email}
                  placeholder={ReactHtmlParser(t("Email"))}
                  disabled={disabled}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      );
    });
  };

  return <>{renderReferrals()}</>;
}
