import React, { useState, useEffect } from "react";
import { useDetectAdBlock } from "adblock-detect-react";
import { Button, Modal } from "react-bootstrap";

export default function AdBlockPopup() {
  const [adBlock, setAdBlock] = useState(true);
  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    setAdBlock(adBlockDetected);
  }, []);

  useEffect(() => {
    setAdBlock(adBlockDetected);
  }, [adBlockDetected]);

  const handleClose = () => {
    setAdBlock(false);
  };

  return (
    <div className="container">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        show={adBlock}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>It looks like you are using an Ad Blocker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We do not display any ads on our website, however this website does
            not function correctly with an Ad Blocker enabled.
          </p>
          <p>Please disable your Ad Blocker in order to continue.</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            className="btnNext btn btn-primary btn-AdsPopup"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
