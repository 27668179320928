import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GA4React from "ga-4-react";

import "./i18n";

const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_G4_ID);

(async (_) => {
  await ga4react
    .initialize()
    .then((res) => {})
    .catch((err) => {})
    .finally(() => {
      ReactDOM.render(
        <BrowserRouter>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </BrowserRouter>,
        document.getElementById("root")
      );
    });
})();

reportWebVitals();
