import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";

import { Header } from "../../components/hearingtest";

import Instructions from "../../views/instructions/Instructions";
import VolumeSetting from "../../views/volumeSetting/VolumeSetting";
import HearingTestStepWizard from "../../views/hearingTestStepWizard/HearingTestStepWizard";
import PhoneticallyWordsStepWizard from "../../views/phoneticallyWordsStepWizard/PhoneticallyWordsStepWizard";
import Contact from "../../views/contact/Contact";
import Report from "../../views/report/Report";
import ThankYou from "../../views/thankYou/ThankYou";

import SpinningLoader from "../../components/spinningLoader/SpinningLoader";

const DefaultLayout = (props) => {
  const [loading, setLoading] = useState(false);

  const showSpinner = (load = false) => {
    setLoading(load);
  };

  return (
    <>
      <SpinningLoader active={loading}>
        <Header />
        <Container fluid className="container main-container p-0 w-80 mw-80">
          <main className="main">
            <div className="content">
              <Route
                path="/instructions/:id"
                name="Instructions"
                exact
                render={(props) => <Instructions {...props} />}
              />
              <Route
                path="/volumesetting/:id"
                name="Volume Setting"
                exact
                render={(props) => <VolumeSetting {...props} />}
              />

              <Route
                path="/test/:id/hearingtest/:hz/ear/:ear/"
                name="HearingTest StepWizard"
                exact
                render={(props) => (
                  <HearingTestStepWizard onLoading={showSpinner} {...props} />
                )}
              />

              <Route
                path="/test/:id/words/:hz/word/:word/"
                name="PhoneticallyWords StepWizard"
                exact
                render={(props) => (
                  <PhoneticallyWordsStepWizard
                    onLoading={showSpinner}
                    {...props}
                  />
                )}
              />

              <Route
                path="/contact"
                name="Contact"
                exact
                render={(props) => (
                  <Contact onLoading={showSpinner} {...props} />
                )}
              />

              <Route
                path="/report/:id"
                name="Report"
                exact
                render={(props) => (
                  <Report onLoading={showSpinner} {...props} />
                )}
              />

              <Route
                path="/thankyou/:id"
                name="Thank You"
                exact
                render={(props) => (
                  <ThankYou onLoading={showSpinner} {...props} />
                )}
              />

              <Redirect path="/" to="/contact" />
            </div>
          </main>
          <footer className="footer" />
        </Container>
      </SpinningLoader>
    </>
  );
};

export default DefaultLayout;
